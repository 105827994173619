<template>
  <v-form ref="stepThree" lazy-validation>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-radio-group v-model="isAllStudent" row>
          <v-row no-gutters>
            <v-radio
              v-for="item in allStudent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :readonly="!isEdit"
            />
          </v-row>
        </v-radio-group>
        <v-spacer />
        <v-select
          :items="valueFilter"
          :label="$t('e_test.value_filter')"
          item-value="id"
          item-text="name"
          outlined
          hide-details
          dense
          class="shrink d-inline-block"
          style="max-width: 200px"
          @change="changeFilter"
        />
      </v-col>
      <v-col cols="12" class="mb-5">
        <v-text-field
          v-model="search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          @click:clear="searchHandler()"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="dataStudents.studentsModel"
          :headers="tableHeaders"
          :items="dataStudents.dataTable.data"
          :loading="dataStudents.loading"
          item-key="name"
          show-select
          class="elevation-0"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox v-if="!isEdit" :value="item.selected" disabled />
            <v-simple-checkbox
              v-else
              :value="isSelected"
              :disabled="isAllStudent"
              @input="select($event)"
            />
          </template>
          <template #item.number="{item}">
            {{ dataStudents.dataTable.data.indexOf(item) + 1 }}
          </template>
          <template #header.data-table-select>
            {{ continuation ? $t("e_test.continuation") : $t("e_test.remedy") }}
          </template>
        </v-data-table>
        <!-- <Pagination
          :length="dataStudents.dataTable.last_page"
          :total="dataStudents.dataTable.total"
          :current-page="dataStudents.request.page"
          :limit="dataStudents.request.limit"
          :handler="paginateChange"
        /> -->
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    isEdit: Boolean,
    dataStudents: Object,
    bodyRequest: Object,
    reset: Boolean,
    continuation: Boolean
  },
  components: {
    // Pagination: () => import("@/components/Pagination")
  },
  computed: {
    search: {
      set(value) {
        this.searchHandler(value);
      },
      get() {
        return this.dataStudents.request.search;
      }
    }
  },
  watch: {
    tableModel(newValue) {
      console.log(newValue);
    },
    isAllStudent(newValue) {
      this.selectAllStudent(newValue);
    },
    reset(value) {
      if (value) {
        this.$refs.stepThree.reset();
        this.$refs.stepThree.resetValidation();
      }
    }
  },
  data() {
    return {
      tableHeaders: this.getHeader(),
      isAllStudent: false,
      allStudent: [
        { name: this.$i18n.t("e_test.all_students"), id: true },
        { name: this.$i18n.t("e_test.certain_students"), id: false }
      ],
      valueFilter: [
        { name: this.$i18n.t("app.all"), id: "all" },
        { name: this.$i18n.t("e_test.under") + " 70", id: "70" },
        { name: this.$i18n.t("e_test.under") + " 60", id: "60" }
      ]
    };
  },
  methods: {
    getHeader() {
      let header = [
        { text: "#", value: "number", width: 70 },
        {
          text: this.$i18n.t("app.name"),
          value: "name"
        },
        {
          text: this.$i18n.t("app.score"),
          value: "score",
          width: 130
        },
        {
          text: "",
          value: "data-table-select",
          width: 100
        }
      ];
      if (this.continuation) header.splice(2, 1);
      return header;
    },
    searchHandler(search) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.emitSearch(search);
      }, doneTypingInterval);
    },
    selectAllStudent(all) {
      this.$emit("selectAll", all);
    },
    changeFilter(value) {
      this.$emit("change", value);
    },
    emitSearch(newValue) {
      this.$emit("search", newValue);
    },
    paginateChange(page, limit) {
      this.$emit("pagination", { page: page, limit: limit });
    }
  }
};
</script>
